<template>
  <div class="content-area">
    <hero-bar>
      {{ $t('transferRequest') }}
      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{'is-loading': isLoading}"
        @click="submit"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <form ref="form" @submit.prevent="submit">
      <section class="section is-main-section">
        <card-component :title="$t('requestForm')" icon="code-greater-than-or-equal">
          <tiles>
            <div class="columns is-centered is-fullwidth">
              <div>
                <b-field class="has-text-centered" :label="$t('transferDay')" />
                <b-datetimepicker
                  v-model="form.date"
                  :min-datetime="minDateTime"
                  :timepicker="{ incrementMinutes: 5 }"
                  :datepicker="{
                    monthNames: $t('dateTimePicker.monthNames'),
                    dayNames: $t('dateTimePicker.dayNames'),
                    firstDayOfWeek: 1
                  }"
                  inline
                  size="is-medium"
                />
              </div>
            </div>
            <div class="is-fullwidth">
              <b-field :label="$t('startAddress')">
                <gmap-places
                  :place.sync="form.start_location"
                  :adress="(form.start_location.formatted_address) ? form.start_location.formatted_address : ''"
                  :placeholder="$t('enterStartAdress')"
                />
              </b-field>
              <div
                v-if="form.start_location"
                class="is-user-avatar has-text-centered google-image"
              >
                <gmap-image
                  v-if="form.start_location.latlng"
                  :latlng="form.start_location.latlng"
                  color="0x7957d5"
                  :size="[300, 300]"
                />
              </div>
              <!-- <div class="self-drive-area">
                <b-switch
                  v-model="form.self_drive"
                  :rounded="false"
                  :outlined="false"
                  type="is-purble"
                  size="is-medium"
                >
                  {{ $t('selfDriveRequest') }}
                </b-switch>
              </div> -->
            </div>
            <div class="is-fullwidth">
              <b-field :label="$t('targetAddress')">
                <gmap-places
                  :place.sync="form.target_location"
                  :adress="(form.target_location.formatted_address) ? form.target_location.formatted_address : ''"
                  :placeholder="$t('enterTargetAdress')"
                />
              </b-field>
              <div
                v-if="form.target_location"
                class="is-user-avatar has-text-centered google-image"
              >
                <gmap-image
                  v-if="form.target_location.latlng"
                  :latlng="form.target_location.latlng"
                  :size="[300, 300]"
                />
              </div>
            </div>
          </tiles>
          <div class="is-fullwidth">
            <b-field :label="$t('aciklama')">
              <b-input
                v-model="form.comment"
                maxlength="200"
                type="textarea"
              />
            </b-field>
          </div>
        </card-component>
        <div class="flex-left">
          <div
            v-for="(passenger, index) in form.additional_passengers"
            :key="index"
            class="passenger_box"
          >
            <card-component
              :title="(index + 1) +'. '+ $t('passenger')"
              header-icon="close-box"
              @header-icon-click="deletePassenger(index)"
            >
              <b-field :label="$t('fullname')">
                <b-input
                  v-model="form.additional_passengers[index].name"
                  icon="rename-box"
                  maxlength="50"
                  custom-class="additional_passenger_name"
                />
              </b-field>
              <b-field :label="$t('phoneNumber')">
                <b-input
                  v-model="form.additional_passengers[index].phone"
                  v-cleave="masks.phone"
                  type="tel"
                  icon="cellphone"
                  @focus="focusInput"
                />
              </b-field>
            </card-component>
          </div>
          <card-component
            class="add_additional_passenger"
            @click.native="addPassenger"
          >
            <b-tooltip
              :label="$t('addPassenger')"
              type="is-dark"
            >
              <b-icon icon="account-plus" size="is-large" type="is-light" />
            </b-tooltip>
          </card-component>
        </div>
      </section>
    </form>
  </div>
</template>
<script>
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.tr'
import moment from 'moment'

export default {
  name: 'TransferRequest',
  components: {
    CardComponent: () => import('@/components/CardComponent'),
    HeroBar: () => import('@/components/HeroBar'),
    Tiles: () => import('@/components/Tiles'),
    GmapPlaces: () => import('@/components/GMapPlacesInput'),
    GmapImage: () => import('@/components/GMapImage'),
  },
  title: ({ $i18n }) => $i18n.t('titles.transferRequest'),
  directives: {
    cleave: {
      inserted: (el, binding) => {
        const input = el.querySelector('input')
        input.cleave = new Cleave(input, binding.value || {})
      },
      update: (el) => {
        const input = el.querySelector('input')
        const event = new Event('input', {bubbles: true});
        setTimeout(() => {
          input.value = input.cleave.properties.result
          input.dispatchEvent(event)
        }, 100);
      },
      unbind (el) {
        const input = el.querySelector('input')
        input.cleave.destroy()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      formValid: true,
      form: {
        date: null,
        start_location: {},
        target_location: {},
        comment: '',
        additional_passengers: [],
        self_drive: false,
      },
      masks: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
          delimiter: ' '
        }
      }
    }
  },
  computed: {
    minDateTime () {
      return this.calcTransferDate()
    }
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  created () {
    this.loadTransferDetails()
    this.$store.commit('setAsideActiveMenuKey', 'transfers.index')
  },
  mounted () {
    this.form.date = this.minDateTime
  },
  methods: {
    loadTransferDetails () {
      if (this.$route.params.id) {
        this.showSpinner();

        this.$http
          .get(`transfer/${this.$route.params.id}`)
          .then(({ data }) => {
            this.form.additional_passengers = data.data.attributes.passengers
            this.form.comment = data.data.attributes.user_comment
            this.form.date = new Date(data.data.attributes.transfer_date)
            this.form.start_location.formatted_address = data.data.attributes.start_address
            this.form.target_location.formatted_address = data.data.attributes.target_address
            this.form.start_location.latlng = data.data.attributes.start_location
            this.form.target_location.latlng = data.data.attributes.target_location
        })
        .finally(() => {
          this.hideSpinner()
        });
      }
    },
    submit () {
      const errors = this.validate()

      if (errors.length > 0) {
        this.$buefy.toast.open({
          message: errors.join('<br>'),
          type: 'is-danger',
          duration: 5000,
        });

        return false
      }

      this.isLoading = true

      /* const [start_lat, start_lng] = this.form.start_location.latlng.split(',')
      const [target_lat, target_lng] = this.form.target_location.latlng.split(',')
      const directionsService = new window.google.maps.DirectionsService()

      directionsService.route({
        origin: new window.google.maps.LatLng(start_lat, start_lng),
        destination: new window.google.maps.LatLng(target_lat, target_lng),
        travelMode: 'DRIVING',
        drivingOptions: {
          departureTime: this.form.date,
          trafficModel: 'bestguess',
        },
      }, ({ routes }, status) => {
        let estimated_time = null

        if (status === 'OK') {
          estimated_time = moment(this.form.date).add(routes[0].legs[0].duration_in_traffic.value, 'seconds')
        }

        if (moment.isMoment(estimated_time)) {
          estimated_time = estimated_time.add(5 - ((estimated_time.minutes() % 5) || 5), 'minutes').format('YYYY-MM-DD HH:mm').toString()
        }

        // create transfer
      }) */

      const params = {
        passenger_id: this.$store.getters.authUser.id,
        start_address: this.form.start_location.formatted_address,
        start_location: this.form.start_location.latlng,
        target_address: this.form.target_location.formatted_address,
        target_location: this.form.target_location.latlng,
        user_comment: this.form.comment,
        passengers: this.form.additional_passengers,
        self_drive: this.form.self_drive,
        transfer_date: moment(this.form.date).format('YYYY-MM-DD HH:mm').toString(),
      }

      this.$http.post('create-transfer', params).then(({ data }) => {
        if (data.success) {
          this.$buefy.toast.open({
            message: this.$t('transferCreated'),
            type: 'is-success',
            duration: 5000,
          })

          this.$router.push('/transfers')
        }
      }).catch(e => {
        const errors = this.$_.flattenDeep(this.$_.values(e.response.data.error.errors)).join('<br>')

        this.$buefy.toast.open({
          message: this.$t('error') + ': <br>' + errors,
          type: 'is-warning',
          duration: 5000,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    validate () {
      const errors = []

      if (!this.form.date) {
        errors.push('Tarih seçimi yapılmadı veya hatalı giriş yaptınız.')
      }

      if (!this.form.start_location.formatted_address || !this.form.start_location.latlng) {
        errors.push('Başlangıç Adresi hatalı.')
      }

      if (!this.form.target_location.formatted_address || !this.form.target_location.latlng) {
        errors.push('Hedef Adresi hatalı.')
      }

      return errors
    },
    addPassenger () {
      this.form.additional_passengers.push({ name: null, phone: '+90' });
      setTimeout(() => {
        let passengerNameInputs = document.getElementsByClassName('additional_passenger_name');
        let lastPassengerNameInputs = passengerNameInputs[passengerNameInputs.length - 1];
        lastPassengerNameInputs.focus();
      }, 100)
    },
    deletePassenger (index) {
      this.form.additional_passengers.splice(index, 1);
    },
    focusInput (e) {
      this.$nextTick()
        .then(() => {
          const vals = e.target.value;
          e.target.value = '';
          e.target.value = vals;
      })
    },
    calcTransferDate () {
      let minute = moment().format('m');
      let curDate = moment();

      if (!this.checkPermission('set_driver')) {
        curDate.add(3, 'hours');
      }

      minute = (minute % 5);
      return curDate.subtract(minute, 'minutes').add(15, 'minutes').toDate()
    },
  }
}
</script>

<style scoped>
.self-drive-area {
  margin-top:40px;
}

.google-image {
  height: 307px;
}
</style>